import firebase from 'firebase/app'
import 'firebase/auth'
import 'firebase/firestore'
import 'firebase/analytics'
// firebase init - add your own config here
const firebaseConfig = {
  apiKey: 'AIzaSyC7iwfQihdsaqEH1JVWOoum5EageY2_bVM',
  authDomain: 'owncast-easy.firebaseapp.com',
  databaseURL: 'https://owncast-easy.firebaseio.com',
  projectId: 'owncast-easy',
  storageBucket: 'owncast-easy.appspot.com',
  messagingSenderId: '800954588922',
  appId: '1:800954588922:web:a4dfb4f386d26b38c2b343',
  measurementId: "G-3XDK9ENZYW"
}
firebase.initializeApp(firebaseConfig)

// utils
const db = firebase.firestore()

//enable long polling workaround
firebase.firestore().settings({ experimentalAutoDetectLongPolling: true, merge: true });
//firebase.firestore().settings({ experimentalForceLongPolling: true, merge: true });

const serverTs = firebase.firestore.FieldValue.serverTimestamp()
const auth = firebase.auth()
const analytics = firebase.analytics()


// collection references
const tenantsCollection = db.collection('tenants')

const authPersistence = firebase.auth.Auth.Persistence

// export utils/refs
export {
  db,
  auth,
  authPersistence,
  serverTs,
  analytics,
  tenantsCollection,
}