import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home
  },
  {
    path: '/admin/:tenantname',
    name: 'Admin Home',
    component: () => import(/* webpackChunkName: "tenantadmin" */ '../views/TenantAdmin.vue'),
  },
  {
    path: '/:tenantname',
    name: 'Tenant Home',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "tenanthome" */ '../views/TenantHome.vue'),
    children: [
      {
        path: ':eventname',
        component: () => import(/* webpackChunkName: "event" */ '../views/Event.vue'),
      },
      {
        path: ':eventname/questions',
        component: () => import(/* webpackChunkName: "questionviewer" */ '../views/QuestionsViewer.vue'),
      },
      
    ]
  },
  
]

const router = new VueRouter({
  mode: 'history',
  routes,
})

export default router
